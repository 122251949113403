$(function(){
	if( PROSKTR.content !== "product_info"){
		return true;
	}
	setViewedProduct();
	initStatistics()

	function setViewedProduct() {
		if ($('.js-product-data').length) {
			cookieObj.addViewedProduct($('.js-product-data').attr('data-products-id').toString());
		}
	}

	function initStatistics(){
		$('.js-product-info-send-statistics').click(function(){
			var isDisabled = $(this).prop('disabled');

			if(isDisabled){
				return true
			}

			var productName = $(this).data('product-name'),
				productId = $(this).data('product-id'),
				productBrand = $(this).data('product-brand'),
				productPrice = $(this).data('product-price'),
				productMainCategory = $(this).data('product-main-category'),
				productCategory = $(this).data('product-category');

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
			var dataStatistics = {
				currency: 'RUB',
				value: productPrice,
				items: [{
					item_name: productName,
					item_id: productId,
					price: productPrice,
					item_brand: productBrand,
					item_category: productMainCategory,
					item_category2: productCategory,
					item_category3: '',
					item_list_name: '',//�������� ������ �������
					item_list_id: '',// id ������ �������
					index: '',
					quantity: 1
				}]
			};
			dataLayer.push({
				event: 'add_to_cart',
				ecommerce: dataStatistics,
			});
		});
	}
});