$(function(){
	initProductFromProductPage();
	initProductFromBasketModal();
	initWishList();
	initSorting();
	initProductsStatistics();

	function initSorting(){
		$('.js-selected-list-item').click(function(){
			$(this).parent().fadeOut();
		});
	}

	function initWishList(){
		$(document).on('click', '.js-wishlist-add', function(e) {
			e.preventDefault();

			var el = this;
			if( $(this).attr('data-wishlist') == 1 ) {
				wishlist.remove( $(this).attr('data-product-id'), function() {
					$(el).data('wishlist', 0);
					$(el).removeClass('active');
				} );
			} else {
				wishlist.add( $(this).attr('data-product-id'), function() {
					$(el).data('wishlist', 1);
					$(el).addClass('active');
				} );
			}
		});
	}

	function initProductFromBasketModal(){
		initChangeQuantity();
		initChangeSize();
		initDeleteProduct();

		function initDeleteProduct(){
			$('.js-basket-modal-products-list').on('click', '.js-cart-delete', function(e) {
				e.preventDefault();
				shoppingCart.deleteProduct({
					products_id: $(this).attr('data-products-id')
				});
				$(this).closest('.js-basket-product-item').remove();
			});
		}

		function initChangeQuantity(){
			$('.js-basket-modal-products-list').on('change', '.js-basket-change-count', function(e) {
				e.preventDefault();
				shoppingCart.changeProductCount(getProductId($(this)),$(this).val());
				setNewProductData($(this));
				sendStatistics($(this));
			});

			function setNewProductData(quantityElement){
				var productDataElement = $(quantityElement).closest('.js-basket-product-item'),
					productQuantity = $(quantityElement).val();

				$(productDataElement).attr('data-quantity',productQuantity);
			}

			function getProductId(quantityElement){
				var productDataElement = $(quantityElement).closest('.js-basket-product-item'),
					productId = $(productDataElement).attr('data-product-id'),
					productOptionId = $(productDataElement).attr('data-option-id'),
					productOptionValue = $(productDataElement).attr('data-option-value');

				return productId+"{"+productOptionId+"}"+productOptionValue;
			}

			function sendStatistics(el){
				var isIncrease = $(el).attr('data-old-value') < $(el).val(),
					isDecrease = $(el).attr('data-old-value') > $(el).val();

				$(el).attr('data-old-value', $(el).val());
				if(isIncrease){
					var productData = $(el).closest('.js-basket-product-item'),
						productId = productData.data('product-id'),
						productName = productData.data('product-name'),
						productBrand = productData.data('product-brand'),
						productPrice = productData.data('product-price'),
						productMainCategory = productData.data('product-main-category'),
						productCategory = productData.data('product-category'),
						productQuantity = $(el).val(),
						productListName = sessionStorage.getItem('product_item_list_name_'+productId) ? sessionStorage.getItem('product_item_list_name_'+productId) : '',
						productListId = sessionStorage.getItem('product_item_list_id_'+productId) ? sessionStorage.getItem('product_item_list_id_'+productId) : '',
						productPositionInList = sessionStorage.getItem('product_index_'+productId) ? sessionStorage.getItem('product_index_'+productId) : '';

					window.dataLayer = window.dataLayer || [];
					dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
					var dataStatistics = {
						currency: 'RUB',
						value: productPrice,
						items: [{
							item_name: productName,
							item_id: productId,
							price: productPrice,
							item_brand: productBrand,
							item_category: productMainCategory,
							item_category2: productCategory,
							item_category3: '',
							item_list_name: productListName,//�������� ������ �������
							item_list_id: productListId,// id ������ �������
							index: productPositionInList,
							quantity: productQuantity
						}]
					};
					dataLayer.push({
						event: 'add_to_cart',
						ecommerce: dataStatistics
					});
				}
			}
		}

		function initChangeSize(){
			$('.js-basket-modal-products-list').on('change', '.js-basket-change-size', function(e) {
				e.preventDefault();

				var productSizeElement = $(this).closest('.js-basket-product-item').find('.js-basket-product-size'),
					sizeText = $(this).find('option:selected').text()

				$(productSizeElement).text(sizeText);
				$.post('/ajax_shopping_cart.php?action=update_product_new',getProductData($(this)))
					.done(function( data ) {
						shoppingCart.reloadPopupCallback();
					});
				setNewProductData($(this));
			});

			function setNewProductData(sizeElement){
				var selectOptionData = $(sizeElement).val().split('|'),
					selectOptionId = selectOptionData[0],
					selectOptionValue = selectOptionData[1],
					productDataElement = $(sizeElement).closest('.js-basket-product-item');

				$(productDataElement).attr('data-option-id',selectOptionId);
				$(productDataElement).attr('data-option-value',selectOptionValue);
			}

			function getProductData(sizeElement){
				var selectOptionData = $(sizeElement).val().split('|'),
					selectOptionId = selectOptionData[0],
					selectOptionValue = selectOptionData[1],
					productDataElement = $(sizeElement).closest('.js-basket-product-item'),
					productId = $(productDataElement).attr('data-product-id'),
					productQuantity = $(productDataElement).attr('data-quantity'),
					productOptionId = $(productDataElement).attr('data-option-id'),
					productOptionValue = $(productDataElement).attr('data-option-value');

				return [
					{
						"name": "id["+productId+"{"+productOptionId+"}"+productOptionValue+"]["+productOptionId+"]",
						"value": productOptionValue
					},
					{
						"name": "products_id[]",
						"value": productId+"{"+productOptionId+"}"+productOptionValue
					},
					{
						"name": "cart_size["+productId+"{"+productOptionId+"}"+productOptionValue+"]",
						"value": productId+"{"+selectOptionId+"}"+selectOptionValue
					},
					{
						"name": "cart_quantity["+productId+"{"+productOptionId+"}"+productOptionValue+"]",
						"value": productQuantity
					}
				];
			}
		}

	}

	/**
	 * �������� � ��������
	 */
	function initProductFromProductPage() {
		initChangeSize();
		initAddToBasket();

		function initAddToBasket() {
			$(document).on('click', '.js-add-product', function (e) {
				if(typeof generalPixel === "object") {
					generalPixel.postClick('a1')
				}

				var me = $(this),
					isDisabled = getProductSizeDisabled(me);

				e.preventDefault();
				if(isDisabled){
					$(this).addClass('error-empty-count');
					setTimeout(function(){
						$(me).removeClass('error-empty-count');
					},3000);
					return true;
				}else{
					$(me).removeClass('error-empty-count');
				}

				var productData = getProductDataArr(me);

				if(productData.length) {
					$(this).addClass('m-preloader');
					shoppingCart.addProducts({
						product_info: productData,
					}, {
						checkDublicate: false
					});
					$('.js-basket-modal-block').addClass('active');
				}
			});
		}

		function getProductSizeDisabled(element){
			if($(element).attr('data-option-disabled')){
				return Number($(element).attr('data-option-disabled'));
			}

			var dataProductEl = $(element).closest('.js-product-data');

			return Number($(dataProductEl).attr('data-option-disabled'));
		}

		function initChangeSize(){
			$(document).on('click', '.js-change-product-size', function (e) {
				var dataProductEl = $(this).closest('.js-product-data');

				e.preventDefault();
				$(dataProductEl).attr('data-option-name',$(this).attr('data-option-name'))
					.attr('data-option-value',$(this).attr('data-option-value'))
					.attr('data-option-disabled',$(this).attr('data-option-disabled'));
				setActiveSize($(this));

				function setActiveSize(currentSizeEl){
					$(currentSizeEl).closest('.js-product-sizes-list').find('.js-change-product-size').removeAttr('data-selected');
					$(currentSizeEl).attr('data-selected','true');
					if($(currentSizeEl).hasClass('empty')){
						$('.js-add-product').attr('disabled','disabled');
					}else{
						$('.js-add-product').removeAttr('disabled');
					}
				}
			});
		}

		function getProductDataArr(element){
			var hasDataProductEl = $(element).closest('.js-product-data').length;

			if(!hasDataProductEl){
				return [];
			}

			var dataProductEl = $(element).closest('.js-product-data');

			return [
				{
					"name": 'id'+($(element).attr('data-option-name') ? $(element).attr('data-option-name') : $(dataProductEl).attr('data-option-name')),
					"value": $(element).attr('data-option-value') ? $(element).attr('data-option-value') : $(dataProductEl).attr('data-option-value')
				},
				{
					"name": "cart_quantity",
					"value": $(dataProductEl).attr('data-cart-quantity')
				},
				{
					"name": "products_id",
					"value": $(dataProductEl).attr('data-products-id')
				},
				{
					"name": "param",
					"value": $(dataProductEl).attr('data-param')
				},
				{
					"name": "presents_artikul",
					"value": $(dataProductEl).attr('data-presents-artikul')
				},
				{
					"name": "selected_attribute",
					"value": $(dataProductEl).attr('data-selected-attribute')
				}
			];
		}
	}

	function initProductsStatistics(){
		$('.js-catalog-products-list').on('click','.js-add-product',function(){
			const PRODUCTS_ON_PAGE = 38;

			var currentPage = $('#filtr-form [name="page"]').length ? $('#filtr-form [name="page"]').val() : 1,
				productData = $(this).closest('.js-product-data'),
				productId    = productData.data('products-id'),
				productName  = productData.data('product-name'),
				productPrice = productData.data('product-price'),
				productBrand = productData.data('product-brand'),
				productMainCategory = productData.data('product-main-category'),
				productCategory = productData.data('product-category'),
				productListName = $('h1.c__title').length ? $('h1.c__title').text() : '',

				productIndex = (1*(1 + $('.js-catalog-products-list').children('.js-product-data').index(productData))) + (PRODUCTS_ON_PAGE*(currentPage - 1));

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
			var dataStatistics = {
				currency: 'RUB',
				value: productPrice,
				items: [{
					item_name: productName,
					item_id: productId,
					price: productPrice,
					item_brand: productBrand,
					item_category: productMainCategory,
					item_category2: productCategory,
					item_category3: '',
					item_list_name: productListName,//�������� ������ �������
					item_list_id: location.pathname,// id ������ �������
					index: productIndex,
					quantity: 1
				}]
			};
			dataLayer.push({
				event: 'add_to_cart',
				ecommerce: dataStatistics,
			});

			sessionStorage.setItem('product_item_list_name_'+productId, productListName);
			sessionStorage.setItem('product_item_list_id_'+productId, location.pathname);
			sessionStorage.setItem('product_index_'+productId, productIndex);
		});
	}
});